// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-artwork-js": () => import("./../../../src/pages/artwork.js" /* webpackChunkName: "component---src-pages-artwork-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-art-post-template-js": () => import("./../../../src/templates/ArtPostTemplate.js" /* webpackChunkName: "component---src-templates-art-post-template-js" */),
  "component---src-templates-design-category-gallery-template-js": () => import("./../../../src/templates/DesignCategoryGalleryTemplate.js" /* webpackChunkName: "component---src-templates-design-category-gallery-template-js" */),
  "component---src-templates-design-post-template-js": () => import("./../../../src/templates/DesignPostTemplate.js" /* webpackChunkName: "component---src-templates-design-post-template-js" */)
}

